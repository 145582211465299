import lodash from 'lodash'
import numeral from 'numeral'

const REGX_EMAIL = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
const REGX_PHONE = /^1\d{10}$/
// const REGX_PWD = /^(?=[0-9A-Za-z]*[0-9])(?=[0-9A-Za-z]*[a-zA-Z])(.{6,})$/
// const REGX_PWD = /^(?!([a-zA-Z]+|\d+)$).*{6,20}$/
const REGX_ID_CARD_18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
const REGX_ID_CARD_15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/

const convertToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const hexToRgba = (hex, opacity = 1) => `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt('0x' + hex.slice(3, 5))},${parseInt('0x' + hex.slice(5, 7))}, ${opacity})`

const toJson = obj => JSON.stringify(obj)

const uuid = () => {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'
  return s.join('')
}

const truncateText = (str, len) => {
  let str_length = 0
  let str_len = 0
  let str_cut = new String()
  str_len = str.length
  for (var i = 0; i < str_len; i++) {
    let a = str.charAt(i)
    str_length++
    if (escape(a).length > 4) {
      //中文字符的长度经编码之后大于4
      str_length++
    }
    str_cut = str_cut.concat(a)
    if (str_length >= len) {
      str_cut = str_cut.concat('...')
      return str_cut
    }
  }
  if (str_length < len) {
    return str
  }
}

const convertToHtml = text => (text !== void 0 ? text.replace(/\n/g, '<br/>') : '')
const utils = {
  convertToBase64,
  hexToRgba,
  toJson,
  uuid,
  convertToHtml,
  cloneDeep: lodash.cloneDeep,
  getCurrentRoute(routes, path) {
    let rootPath = path.match(/(\/?[^\/]+)/g)[0]
    let rootRoute = routes.find(r => r.path === rootPath)
    const findRoute = root => {
      if (root.path === path) {
        return root
      }
      let route = null
      if (root.children) {
        route = root.children.find(r => r && r.path === path)
        if (!route) {
          for (let i = 0; i < root.children.length; i++) {
            if (route) {
              break
            }
            if (root.children[i]) {
              route = findRoute(root.children[i])
            }
          }
        }
      }
      return route
    }
    return findRoute(rootRoute)
  },
  flatAddress(addresses) {
    if (!addresses || addresses.length === 0) {
      return ''
    }
    return addresses.map(item => `${item.province || ''}${item.city || ''}${item.district || ''}${item.addr || ''}`).join('、')
  },
  blurPhone(phone) {
    if (!phone || phone.trim() === '') {
      return ''
    }
    return phone.replace(/(\d{3})(\d{4})(\d{4})/g, '$1****$3')
  },
  thousands(num, scale, char = ',') {
    if (isNaN(parseFloat(num))) {
      return '-'
    }
    if (scale === void 0 || scale < 0) {
      scale = 2
    }
    let suffix = ''
    if (scale > 0) {
      suffix = `.${new Array(scale + 1).join('0')}`
    }
    return numeral(parseFloat(num)).format(`0${char}0${suffix}`)
  },
  isNum(val) {
    return !isNaN(parseFloat(val))
  },
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },
  truncateFileName(fileName, len) {
    if (!len || !fileName) {
      return fileName
    }
    let lastIdx = fileName.lastIndexOf('.')
    let prefixName = fileName.substring(0, lastIdx - 1)
    return truncateText(prefixName, len).concat(fileName.substring(lastIdx - 1))
  },
  truncateText,
  isValidEmail(input) {
    return REGX_EMAIL.test(input)
  },
  isValidPhone(input) {
    return REGX_PHONE.test(input)
  },
  isValidIDCard(input) {
    return REGX_ID_CARD_18.test(input) || REGX_ID_CARD_15.test(input)
  },
  isValidPwd(input) {
    return /^.*\d+.*$/.test(input) && /^.*[a-zA-z]+.*$/.test(input) && input.length >= 6
  },
}
export default utils
