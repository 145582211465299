<template>
  <a-layout class="fit cs-layout qh-layout">
    <a-layout-header ref="header" style="height: 80px">
      <div class="container">
        <div class="logo-box">
          <a href="http://www.gdharmonyfoundation.org/home/index/index.html"><img src="~@/assets/images/logo_full.png" alt="" /></a>
        </div>
        <div class="right-menu">
          <ul class="header-menu" v-show="!showSearch">
            <li class="list-item">
              <a href="http://www.gdharmonyfoundation.org/home/about/index/cate_id/1.html" class="eng">About Us</a>
              <a href="http://www.gdharmonyfoundation.org/home/about/index/cate_id/1.html">认识千禾</a>
              <ul class="drop-down" style="left: 0px">
                <li><a href="http://www.gdharmonyfoundation.org/home/about/index/cate_id/1.html">千禾根源</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/about/index/cate_id/2.html">千禾团队</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/about/index/cate_id/3.html">理事长寄语</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/about/index/cate_id/15.html">公信力及荣誉</a></li>
              </ul>
            </li>
            <li class="list-item">
              <a href="http://www.gdharmonyfoundation.org/home/community/index.html" class="eng">Community</a>
              <a href="http://www.gdharmonyfoundation.org/home/community/index.html">社区公益</a>
              <ul class="drop-down" style="left: -17.1172px">
                <li><a href="http://www.gdharmonyfoundation.org/home/community/index/cate_id/2.html">城市支教</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/community/index/cate_id/29.html">小禾的家</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/community/index/cate_id/3.html">可持续社区</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/community/index/cate_id/4.html">益动Walking Proud</a></li>
              </ul>
            </li>
            <li class="list-item">
              <a href="http://www.gdharmonyfoundation.org/home/advance/index.html" class="eng">Advancement</a>
              <a href="http://www.gdharmonyfoundation.org/home/advance/index.html">行业推动</a>
              <ul class="drop-down" style="left: -8.5px">
                <li><a href="http://www.gdharmonyfoundation.org/home/advance/detail/id/2.html">人才培养</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/advance/detail/id/3.html">平台支持</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/advance/detail/id/4.html">社区公益模式打造</a></li>
              </ul>
            </li>
            <li class="list-item">
              <a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/15.html" class="eng">Disclosure</a>
              <a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/15.html">信息公开</a>
              <ul class="drop-down" style="left: 0px">
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/15.html">捐款公示</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/16.html">捐物公示</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/7.html">年检报告</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/8.html">审计报告</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/9.html">机构刊物</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/14.html">机构党建</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/6.html">规章制度</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/open/index/cate_id/17.html">其他信息公示</a></li>
              </ul>
            </li>
            <li class="list-item">
              <a href="http://www.gdharmonyfoundation.org/home/help/index/cate_id/12.html" class="eng">Engagement</a>
              <a href="http://www.gdharmonyfoundation.org/home/help/index/cate_id/12.html">助力社区</a>
              <ul class="drop-down" style="left: 0px">
                <li><a href="http://www.gdharmonyfoundation.org/home/help/index/cate_id/12.html">我要合作</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/help/index/cate_id/11.html">我要加入</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/help/index/cate_id/10.html">我要捐赠</a></li>
              </ul>
            </li>
            <li class="list-item">
              <a href="http://www.gdharmonyfoundation.org/home/news/index/cate_id/13.html" class="eng">News</a>
              <a href="http://www.gdharmonyfoundation.org/home/news/index/cate_id/13.html">新闻中心</a>
              <ul class="drop-down">
                <li><a href="http://www.gdharmonyfoundation.org/home/news/index/cate_id/13.html">千禾十周年</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/news/index/cate_id/4.html">机构动态</a></li>
                <li><a href="http://www.gdharmonyfoundation.org/home/news/index/cate_id/5.html">新闻报道</a></li>
              </ul>
            </li>
            <li class="list-item">
              <a href="https://project.gdharmonyfoundation.org/qhweb" class="eng">Neighborhood</a>
              <a href="https://project.gdharmonyfoundation.org/qhweb">千禾社区</a>
            </li>
          </ul>
          <div id="getData">
            <input type="search" placeholder="搜索" autocomplete="off" id="toSearch" name="keyword" v-show="showSearch" v-model="keyword" @keyup.enter="goSearch" />
            <i class="search-button" v-show="showSearch" @click="goSearch"><img src="~@/assets/images/sousou.png" alt="" /></i>
            <i class="search-button" @click="showSearch = true" id="to-search" v-show="!showSearch"><img src="~@/assets/images/sousou.png" alt="" /></i>
            <a-icon type="close" class="search-close" v-show="showSearch" @click="cancelSearch" style="font-size: 16px" />
            <input type="hidden" name="__hash__" value="ce55f68a2e0dec17b21b1b2aa091c88e_17df2ceb5cd6b234844ed222bbc0eb38" />
          </div>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content>
      <!-- </a-affix> -->
      <div class="page-wrapper bg-white" ref="container">
        <router-view />
      </div>
    </a-layout-content>
    <a-back-top :visibilityHeight="50" :target="() => this.$refs.container"><a-avatar shape="square" icon="arrow-up" style="color: #fff; background-color: #1890ff; opacity: 0.75" /></a-back-top>
    <a-modal class="modal-preview" :visible="previewVisible" :footer="null" @cancel="cancelPreview" width="auto" :body-style="{ padding: '10px', minWidth: '200px' }">
      <div class="fit text-center">
        <img id="previewImage" class="preview-image" style="max-width: 100%" :src="previewImage" v-if="previewImage" alt="图片"/>
      </div>
    </a-modal>
  </a-layout>
</template>
<script>
// const CryptoJS = require('crypto-js')
export default {
  name: 'DefaultLayout',
  data() {
    return {
      showSearch: false,
      keyword: '',
      selectedKeys: [],
      previewVisible: false,
      previewImage: null,
    }
  },
  methods: {
    rollNavInkBar() {
      this.$refs.headerNavInkBar.style.transform = `translateX(${this.routeIdx * 120 + 20}px)`
    },
    reload() {
      location.reload()
    },
    findSelectedKey() {
      let menu = this.navMenus.find(item => this.$route.fullPath.startsWith(item.path))
      if (menu.children && menu.children.length) {
        menu = menu.children.find(item => this.$route.fullPath.startsWith(item.path))
      }
      this.selectedKeys = [menu.path]
    },
    onMenuSelect({ item, key, keyPath }) {
      console.log(item, key, keyPath)
      // if (this.selectedKeys[0] !== selectedKeys[0]) {
      //   }
      this.selectedKeys = [key]
      this.$router.push(key)
      // this.selectedKeys = [...selectedKeys]
      // console.log(item, key, selectedKeys)
      // this.selectedKeys = [...selectedKeys]
    },
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    onMenuClick({ item, key, keyPath }) {
      console.log({ item, key, keyPath })
      // console.log(keyPath.reverse().join('/'))
      this.$router.push(keyPath.reverse().join('/'))
    },
    handlePreviewImage(file) {
      this.previewImage = file
      this.previewVisible = true
    },
    cancelPreview() {
      this.previewVisible = false
      this.$nextTick(() => {
        // this.previewImage = null
      })
    },
    cancelSearch() {
      this.showSearch = false
      this.keyword = ''
    },
    goSearch() {
      console.log('gosearch', this.keyword)
      window.open('http://www.gdharmonyfoundation.org/home/search/index.html?keyword=' + this.keyword.trim(), '_blank')
    },
  },
  mounted() {
    this.$root.$on('previewImage', this.handlePreviewImage)
    console.log(this.$route)
  },
}
</script>

<style lang="stylus">
.qh-layout
  .ant-layout-header
    font-size 14px
    background #fff
    box-shadow 0 2px 8px 0 rgba(95, 101, 105, 0.16)
    z-index 2
    padding 0
    .container
      display flex
      align-items center
      height 100%
      width 1200px
      margin 0 auto
      justify-content space-between
      .logo-box
        line-height 50px
        a
          display inline-block
          height 50px
          img
            max-width 100%
      .right-menu
        width 70.66%
        ul li.list-item
          float left
          display flex
          flex-direction column
          padding-bottom 5px
          position relative
          line-height 20px
          &:hover .drop-down
            display block
          &:before
            content ''
            position absolute
            width 2px
            height 45px
            top 0
            right 0
            background #c3c3c3
            transform rotate(15deg)
          a
            width 115px
            padding 2.5px 0
            text-align center
            color #333
            &.eng
              color #f4a000
              font-weight 500
          .drop-down
            box-shadow 0px 2px 10px 0px rgba(50, 49, 48, 0.1)
            display none
            position absolute
            left 0
            top 55px
            background #fff
            z-index 999
            border 1px solid #fdebc9
            &:before
              width 0px
              height 0px
              position absolute
              top -12px
              right 45%
              padding 0
              border-bottom 6px solid #FFFFFF
              border-top 6px solid transparent
              border-left 6px solid transparent
              border-right 6px solid transparent
              display block
              content ''
              z-index 12
              transform translateX(-37%)
            &:after
              width 0px
              height 0px
              position absolute
              top -14px
              right 43.5%
              padding 0
              border-bottom 7px solid #fdebc9
              border-top 7px solid transparent
              border-left 7px solid transparent
              border-right 7px solid transparent
              display block
              content ''
              z-index 10
              transform translateX(-43.5%)
            li a
              width auto
              min-width 115px
              text-align center
              box-sizing border-box
              height 35px
              color #333
              padding 10px
              white-space nowrap
              display flex
              justify-content center
              align-items center
              &:hover
                background #f4a000
                color #fff
        #getData
          height 50px
          display flex
          position relative
          justify-content flex-end
          input
            outline none
            border none
            border-bottom 2px solid #eee
            width 80%
            padding-left 40px
          i, a
            height 100%
            display flex
            align-items center
            justify-content center
            cursor pointer
          .search-close
            position absolute
            left 17%
  .ant-layout-sider
    box-shadow 5px 0px 5px rgba(0, 0, 0, 0.08)
    color #fff
    .sider-bg
      background-color rgba(0, 21, 41, 1)
      position absolute
      left 0
      top 0
      bottom 0
      right 0
      background-position left top
      background-repeat no-repeat
      background-size cover
</style>
