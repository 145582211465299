import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

import './boot/antd'
import './boot/vue-echarts'

// import vcolorpicker from 'vcolorpicker'
// Vue.use(vcolorpicker)

import mixin from './mixins'
Vue.mixin(mixin)

import utils from './utils/utils'
Vue.prototype.$utils = utils

import './assets/css/font-awesome.min.css'
import './style/index.styl'

Vue.prototype.$moment = moment
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
