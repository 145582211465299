const app = {
  state: {
    roadshowCates: [{ label: '发行路演' }, { label: '上市仪式' }, { label: '集体接待日' }, { label: '项目路演' }, { label: '业绩说明会' }, { label: '其他' }],
    roadshowTypes: [
      { label: '开放式', value: 0 },
      { label: '封闭式', value: 1 },
    ],
    roadshowStates: [
      { label: '关闭', value: 0 },
      { label: '开启', value: 1 },
    ],
    speakTypes: [
      { label: '公司经营', value: '0' },
      { label: '发行情况', value: '1' },
      { label: '社会责任及分红', value: '2' },
      { label: '市值管理', value: '3' },
      { label: '公司财务情况', value: '4' },
      { label: '其他', value: '5' },
    ],
    styleList: [
      { label: '蓝色', desc: 'blue', value: 'blue', color: '#2E7DF6' },
      { label: '红色', desc: 'red', value: 'red', color: '#BA2020' },
      { label: '金色', desc: 'gold', value: 'gold', color: '#E8CC90' },
    ],
    stages: [
      { label: '未启动', value: 0 },
      { label: '执行中', value: 1 },
      { label: '结项中', value: 2 },
      { label: '已结项', value: 3 },
      { label: '已终止', value: -1 },
    ],
    subjects: [{ label: '社区教育' }, { label: '社区环境' }, { label: '能力建设' }, { label: '社区公益' }],
    changeTypes: [{ label: '项目名称' }, { label: '项目负责人' }, { label: '项目周期' }, { label: '项目预算' }, { label: '项目实施地点' }],
    orgTypes: [{ label: '社会团体' }, { label: '民办非企业' }, { label: '企业' }, { label: '基金会' }],
    nationalities: [{ label: '中国大陆籍' }],
    duties: [{ label: '项目管理与执行' }, { label: '项目执行' }, { label: '项目传播' }, { label: '项目筹资' }, { label: '项目监测与评估' }, { label: '项目财务' }, { label: '不当行为防治' }, { label: '社区学习与发展顾问' }, { label: '课程研发顾问' }],
  },
  mutations: {
    getRoadshowCates(state, data) {
      console.log('[vuex]getRoadshowCates', data)
      state.levels = data || []
    },
    getRoadshowTypes(state, data) {
      console.log('[vuex]getRoadshowTypes', data)
      state.strategies = data || []
    },
    getRoadshowStates(state, data) {
      console.log('[vuex]getRoadshowStates', data)
      state.states = data || []
    },
  },
  actions: {
    getRoadshowCates({ commit }, options) {
      commit('getRoadshowCates', options)
    },
    getRoadshowTypes({ commit }, options) {
      commit('getRoadshowTypes', options)
    },
    getRoadshowStates({ commit }, options) {
      commit('getRoadshowStates', options)
    },
  },
}

export default app
