<template>
  <div class="files-container">
    <div class="file-list" v-if="downloadFiles.length > 0">
      <div class="file-item" v-for="file in downloadFiles" :key="file.id">
        <img class="file-img" src="~@/assets/images/file_word.svg" v-if="['doc', 'docx'].includes(file.fileFormat)" />
        <img class="file-img" src="~@/assets/images/file_excel.svg" v-else-if="['xls', 'xlsx'].includes(file.fileFormat)" />
        <img class="file-img" src="~@/assets/images/file_ppt.svg" v-else-if="['ppt', 'pptx'].includes(file.fileFormat)" />
        <img class="file-img" src="~@/assets/images/file_compress.svg" v-else-if="['rar', 'zip', 'tar', '7z'].includes(file.fileFormat)" />
        <img class="file-img" src="~@/assets/images/file_txt.svg" v-else-if="file.fileFormat === 'txt'" />
        <img class="file-img" src="~@/assets/images/file_pdf.svg" v-else-if="file.fileFormat === 'pdf'" />
        <img class="file-img" src="~@/assets/images/file_video.svg" v-else-if="file.fileFormat === 'video'" />
        <img class="file-img" src="~@/assets/images/file_default.svg" v-else />
        <span class="text-indigo hover-line overflow-hidden ellipsis" :title="file.fileOriginName" @click="downloadFile({ fileId: file.id, belongId, fileSign, fileName: file.fileOriginName })">{{ $utils.truncateFileName(file.fileOriginName, 40) }}</span>
      </div>
    </div>
    <div class="file-list" v-if="imgFiles.length > 0">
      <div class="file-imgs">
        <div class="file-item-img" :style="{ width: `${imgSize}px`, height: `${imgSize}px`}" v-for="file in imgFiles" :key="file.id" title="点击查看大图" @click="previewImage(file)">
          <img :src="`${baseURL}/file/img?id=${file.id}&belongId=${belongId}&fileSign=${fileSign}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileContainer',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    belongId: {
      type: Number,
    },
    fileSign: {
      type: String,
    },
    imgSize: {
      type: Number,
      default: 48,
    },
  },
  data() {
    return {
      imgFiles: [],
      downloadFiles: [],
    }
  },
  watch: {
    files() {
      this.splitFiles()
    },
  },
  methods: {
    splitFiles() {
      let imgFiles = []
      let downloadFiles = []
      this.files.forEach(file => {
        let { fileFormat } = file
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileFormat)) {
          fileFormat = 'image'
        } else if (['rar', 'zip', '7z'].includes(fileFormat)) {
          fileFormat = 'compress'
        } else if (['xls', 'xlsx'].includes(fileFormat)) {
          fileFormat = 'excel'
        } else if (['doc', 'docx', 'wps'].includes(fileFormat)) {
          fileFormat = 'word'
        } else if (['mp4', 'wmv', 'rmvb', 'avi', 'mov', 'mkv'].includes(fileFormat)) {
          fileFormat = 'video'
        } else if (['ppt', 'pptx'].includes(fileFormat)) {
          fileFormat = 'ppt'
        }
        if (fileFormat === 'image') {
          imgFiles.push(file)
        } else {
          downloadFiles.push(file)
        }
      })
      this.imgFiles = imgFiles
      this.downloadFiles = downloadFiles
    },
    previewImage(file) {
      let { baseURL, belongId, fileSign } = this
      let src = `${baseURL}/file/img?id=${file.id}&belongId=${belongId}&fileSign=${fileSign}`
      this.$root.$emit('previewImage', src)
    },
  },
  mounted() {
    this.splitFiles()
  },
}
</script>
