const TOKEN_KEY = '_$rs.token'
const USER_KEY = '_$rs.user'

const getToken = () => localStorage.getItem(TOKEN_KEY)
const setToken = token => {
  localStorage.setItem(TOKEN_KEY, token)
}
const clearToken = () => localStorage.removeItem(TOKEN_KEY)
const getUser = () => JSON.parse(localStorage.getItem(USER_KEY) || '{}')
const setUser = user => {
  localStorage.setItem(USER_KEY, user)
}
const clearUser = () => localStorage.removeItem(USER_KEY)

export default {
  getToken,
  setToken,
  clearToken,
  getUser,
  setUser,
  clearUser,
}
