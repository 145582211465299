import Vue from 'vue'
import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack
// import 'echarts-liquidfill'
// import 'echarts-liquidfill/src/liquidFill.js'
// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/map'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/chart/heatmap'
import 'echarts/lib/chart/custom'
// import 'echarts/lib/chart/liquidFill'
// import 'echarts/lib/chart/scatter'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/angleAxis'
import 'echarts/lib/component/radiusAxis'

// import 'echarts/extension/bmap/bmap'

// If you want to use ECharts extensions, just import the extension package and it will work
// Taking ECharts-GL as an example:
// You only need to install the package with `npm install --save echarts-gl` and import it as follows
// import 'echarts-gl'
// import 'echarts-liquidfill/src/liquidFill.js'
// require('echarts-liquidfill')
// register component to use
// require('echarts-wordcloud')
Vue.component('v-chart', ECharts)
