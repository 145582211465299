import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultLayout from '../layout/default.vue'
import { message } from 'ant-design-vue'

// import store from '../store/index'

const RouterView = {
  template: '<router-view ></router-view >',
}

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

window.chkVersion = version => {
  let _qh_version = localStorage._qh_version || window._qh_version
  window._qh_version = version
  if (_qh_version !== version) {
    localStorage._qh_version = version
    message.loading('系统更新', 1, () => {
      // 刷新页面
      location.reload(true)
    })
  }
}

const compareVersion = function () {
  if (!window._fof_version) {
    var versionScript = document.createElement('script')
    versionScript.src = 'vcheck.js?v=' + Date.now()
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(versionScript, s)
  }
}

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        redirect: '/login',
      },
      { path: 'login', name: 'login', component: () => import('@/views/login.vue') },
      { path: 'regist', name: 'regist', component: () => import('@/views/regist.vue') },
      { path: 'reset', name: 'reset', component: () => import('@/views/reset.vue') },
      { path: 'error', name: '404', component: () => import('@/views/error.vue') },
      {
        path: 'usercenter',
        component: () => import('@/views/usercenter.vue'),
        children: [
          {
            path: '',
            redirect: 'dashboard',
          },
          { path: 'dashboard', name: '首页', component: () => import('@/views/dashboard/index.vue') },
          {
            path: 'project',
            name: '项目中心',
            component: RouterView,
            children: [
              {
                path: '',
                redirect: 'list',
              },
              { path: 'list', name: '项目列表', component: () => import('@/views/project/list.vue') },
              { path: 'started', name: '执行中项目', component: () => import('@/views/project/started.vue') },
              { path: 'unstarted', name: '申报中项目', component: () => import('@/views/project/unstarted.vue') },
              { path: 'closed', name: '已结束项目', component: () => import('@/views/project/closed.vue') },
              { path: 'apply', name: '申报项目', component: () => import('@/views/project/apply.vue') },
              { path: 'declare', name: '项目申报书', component: () => import('@/views/project/declare.vue') },
              { path: 'view', name: '项目详情', component: () => import('@/views/project/view.vue') },
            ],
          },
          {
            path: 'document',
            name: '项目文档',
            component: RouterView,
            children: [
              {
                path: '',
                redirect: 'list',
              },
              { path: 'list', name: '文档列表', component: () => import('@/views/document/list.vue') },
            ],
          },
          { path: 'profile', name: '个人基本信息', component: () => import('@/views/profile.vue') },
        ],
      },
      { path: '*', redirect: 'error' },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  compareVersion()
  next()
})
export default router
