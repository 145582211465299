import axios from 'axios'
import qs from 'querystring'
import storage from '@/utils/storage'
import router from '@/router'
import { message, Modal } from 'ant-design-vue'

import { baseURL } from './const'

export default () => {
  let instance = axios.create({
    baseURL,
    timeout: 600000,
  })

  const relogin = function (title) {
    Modal.destroyAll()
    Modal.warning({
      cancelText: '',
      title,
      closable: false,
      onOk: () => {
        router.push('/login')
      },
    })
  }

  instance.interceptors.request.use(
    (config) => {
      let token = storage.getToken()
      if (token) {
        config.headers['Authorization'] = token
      }
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // http response 拦截器
  instance.interceptors.response.use(
    (response) => {
      // 授权登录信息错误返回登录页面
      // message.destroy()
      if (response.data && response.data.code === 401) {
        if (router.currentRoute.path !== 'login') {
          relogin('登陆信息已过期，请重新登陆！')
        }
      }
      return response
    },
    (error) => {
      console.log('interceptors error', error.response ? error.response : error)
      message.destroy()
      if (error.response) {
        if (error.response.status === 401) {
          // 401 清除token信息并跳转到登录页面
          // store.dispatch('logout')
          relogin('登陆信息已过期，请重新登陆！')
          return Promise.reject()
        } else if (error.response.status !== 200) {
          return Promise.reject(`服务器响应错误[${error.response.status}]`)
        }
        return Promise.reject(error.response.data)
      } else if (error.message.indexOf('timeout') > -1) {
        return Promise.reject('网络超时')
      }
      return Promise.reject('网络超时')
    }
  )

  const http = {
    get(uri, params) {
      return instance.get(uri, {
        params,
      })
    },
    post(uri, params) {
      return instance.post(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    },
    delete(uri, params) {
      return instance.delete(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    },
    put(uri, params) {
      return instance.put(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    },
    form(uri, params) {
      return instance.post(uri, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    raw(uri, params) {
      return instance.post(uri, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    blob(uri, params) {
      return instance.post(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'blob',
      })
    },
    all: axios.all,
    spread: axios.spread,
    baseURL,
  }
  return http
}
