import storage from '@/utils/storage'
const user = {
  state: {
    token: storage.getToken() || '',
    userinfo: storage.getUser() || {},
  },
  mutations: {
    login(state, { token, userinfo }) {
      state.userinfo = userinfo
      state.token = token
      storage.setToken(token)
      storage.setUser(JSON.stringify(userinfo))
    },
    logout(state) {
      state.userinfo = {}
      state.token = null
      storage.clearToken()
      storage.clearUser()
    },
    setMenuRoutes(state, menuRoutes) {
      state.menuRoutes = menuRoutes
    },
  },
  actions: {
    // 用户登录
    login({ commit }, user) {
      commit('login', user)
    },
    // 登出
    logout({ commit }) {
      commit('logout')
    },
  },
}

export default user
