import { baseURL, homeURL } from '@/utils/const'
import { Empty } from 'ant-design-vue'
import { getFile } from '@/api/file'
import FileContainer from '@/views/common/file-container'
export default {
  components: {
    FileContainer,
  },
  data() {
    return {
      baseURL,
      homeURL,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  computed: {
    userinfo: vm => vm.$store.state.user.userinfo,
    menus: vm => vm.$store.state.user.menus,
    menuRoutes: vm => vm.$store.state.user.menuRoutes,
    // speakTypes: (vm) => vm.$store.state.app.speakTypes,
    appToken: {
      get: vm => vm.$store.state.user.token,
      set: () => {
        // console.log(v)
      },
    },
  },
  methods: {
    goHome() {
      location.href = homeURL
    },
    affixTarget() {
      return document.querySelector('.page-wrapper')
    },
    handlePreviewFile(file) {
      console.log(file)
      let { fileId, belongId, fileBelongId, fileOriginName, name, fileSign, originFileObj, type, fileFormat } = file
      if (originFileObj && type.startsWith('image')) {
        const reader = new FileReader()
        reader.readAsDataURL(originFileObj)
        reader.onload = () => {
          this.$root.$emit('previewImage', reader.result)
        }
      } else if (['png', 'jpg', 'jpeg'].includes(fileFormat)) {
        this.$root.$emit('previewImage', `${this.baseURL}/file/img?id=${file.id}&belongId=${belongId}&fileSign=${fileSign}`)
      } else {
        this.downloadFile({ fileId, fileName: fileOriginName || name, belongId: belongId || fileBelongId, fileSign })
      }
    },
    handleUploadFile(info, count = 1) {
      let { file } = info
      const status = file.status
      if (status == 'done') {
        console.log(info.file, info.fileList)
      }
      let fileList = [...info.fileList]
      if (status === 'done') {
        if (file.response && file.response.success) {
          // file = Object.assign(file, file.response.result)
          this.$message.success(`${info.file.name} 上传成功`)
        }
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      if (status === 'removed') {
        return fileList
      }
      if (status === 'done' || status === 'uploading') {
        fileList = count === -1 ? fileList : fileList.slice(-1 * count)
      } else {
        fileList.splice(fileList.length - 1, 1)
      }
      fileList.forEach(file => {
        if (file.response && file.response.success) {
          file = Object.assign(file, file.response.result)
        }
      })
      return fileList
    },
    beforeUploadFile(file) {
      console.log('beforeUpload', file.name)
      const isOversize = file.size / 1024 / 1024 >= 20
      if (isOversize) {
        this.$message.error('文件大小不能超过20M')
      }
      return !isOversize
    },
    downloadFile({ fileId, fileName, belongId, fileSign }) {
      console.log('downloadFile：', fileId, fileName)
      const exporting = this.$message.loading('正在下载..', 0)
      getFile({ fileId, belongId, fileSign })
        .then(({ status, data }) => {
          setTimeout(exporting, 200)
          if (status === 200) {
            const blob = new Blob([data])
            if ('download' in document.createElement('a')) {
              const dlink = document.createElement('a')
              if (fileName) {
                dlink.download = fileName
              }
              dlink.style.display = 'none'
              dlink.href = URL.createObjectURL(blob)
              document.body.appendChild(dlink)
              dlink.click()
              URL.revokeObjectURL(dlink.href)
              document.body.removeChild(dlink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
          } else if (status === 404) { 
            this.$message.error('文件不存在')
          } else {
            this.$message.error('下载文件失败')
          }
        })
        .catch(() => {
          this.$message.error('下载文件失败')
        })
    },
    downloadByUploader({ fileId, name }) {
      this.downloadFile(fileId, name)
    },
    cascaderFilter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
  },
}
