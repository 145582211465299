<template>
  <!-- <a-locale-provider :locale="zhCN"> -->
  <!-- <App /> -->
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
  <!-- </a-locale-provider> -->
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  name: 'app',
  data() {
    return {
      zhCN,
    }
  },
}
</script>

<style lang="stylus" scoped>
#app
  height 100vh
</style>
