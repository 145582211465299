import Vue from 'vue'
import {
  Pagination,
  Button,
  Layout,
  Input,
  Row,
  Col,
  DatePicker,
  Table,
  Icon,
  Badge,
  Divider,
  Dropdown,
  Menu,
  Select,
  Tag,
  Affix,
  List,
  Avatar,
  Modal,
  BackTop,
  Checkbox,
  Form,
  Upload,
  Popconfirm,
  Skeleton,
  Spin,
  Radio,
  message,
  notification,
  Progress,
  Tabs,
  Timeline,
  Steps,
  ConfigProvider,
  Card,
  InputNumber,
  Slider,
  Result,
  Empty,
  Alert,
  Popover,
  Tooltip,
  Drawer,
  Breadcrumb,
  FormModel,
  TimePicker,
  Switch,
  Space,
  Descriptions,
  Statistic,
  Transfer,
  Tree,
  AutoComplete,
  Cascader
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'

// import { LocaleProvider } from 'ant-design-vue'
// Vue.component(LocaleProvider.name, LocaleProvider)
// Vue.component(Pagination.name, Pagination)
// Vue.component(Button.name, Button)
// Vue.component(Layout.name, Layout)

Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$destroyAll = Modal.destroyAll

// Vue.use(LocaleProvider)
Vue.use(FormModel)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Layout)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Row)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Dropdown)
Vue.use(Badge)
Vue.use(Menu)
Vue.use(Select)
Vue.use(Divider)
Vue.use(Tag)
Vue.use(Affix)
Vue.use(List)
Vue.use(Avatar)
Vue.use(Modal)
Vue.use(BackTop)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Form)
Vue.use(Upload)
Vue.use(Popconfirm)
Vue.use(Skeleton)
Vue.use(Spin)
Vue.use(Progress)
Vue.use(Tabs)
Vue.use(Timeline)
Vue.use(Steps)
Vue.use(ConfigProvider)
Vue.use(Card)
Vue.use(Slider)
Vue.use(Result)
Vue.use(Empty)
Vue.use(Alert)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Drawer)
Vue.use(Breadcrumb)
Vue.use(TimePicker)
Vue.use(Switch)
Vue.use(Space)
Vue.use(Descriptions)
Vue.use(Statistic)
Vue.use(Transfer)
Vue.use(Tree)
Vue.use(AutoComplete)
Vue.use(Cascader)
